<template>
    <uw-content title="退款记录" padding="10px">

        <!-- 分类 -->
        <template slot="prefix">
            <el-button size="mini" :type="search.invoice_state == null ? 'primary' : 'text'" @click="search.invoice_state = null; TableSearch()">全部</el-button>
            <el-button size="mini" :type="search.invoice_state == 1 ? 'primary' : 'text'" @click="search.invoice_state = 1; TableSearch()">已开具发票</el-button>
            <el-button size="mini" :type="search.invoice_state == 0 ? 'warning' : 'text'" @click="search.invoice_state = 0; TableSearch()">未开具发票</el-button>
            <el-button size="mini" :type="search.invoice_state == 2 ? 'warning' : 'text'" @click="search.invoice_state = 2; TableSearch()">无需开具发票</el-button>
        </template>

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="$refs.drawerCreate.Open()">新建退款记录</el-button>
        </template>

        <!-- 表格 -->
        <uw-table>

            <!-- 检索 -->
            <template slot="search">
                <crm-contract-select v-model="search.contract_id" @onChange="TableSearch()" />
                
                <!-- 退款日期 -->
                <el-select v-model="search.pay_date" @change="TableSearch()" placeholder="退款日期" size="mini" clearable filterable>
                    <el-option label="7天内" value="-7 day"></el-option>
                    <el-option label="15天内" value="-15 day"></el-option>
                    <el-option label="30天内" value="-30 day"></el-option>
                    <el-option label="60天内" value="-60 day"></el-option>
                    <el-option label="60天后" value="+60 day"></el-option>
                </el-select>
            </template>

            <!-- 按钮 -->
            <template slot="button">
                <el-button type="primary" size="mini" @click="$refs.xTable.print()" >打印</el-button>
                <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
            </template>

            <!-- 数据 -->
            <template slot="data">
                <vxe-table
                    ref="xTable"
                    show-footer
                    :data="table"
                    :loading="loading"
                    :export-config="{}"
                    :print-config="{}"
                    :footer-method="TableFooterMethod"
                    :seq-config="{startIndex: page.size * (page.current - 1)}"
                    @sort-change="TableSort"
                    height="100%">

                    <!-- 序号 -->
                    <vxe-column width="60" type="seq" title="序号"></vxe-column>

                    <!-- 退款金额 -->
                    <vxe-column width="120" field="pay_money" title="退款金额" sortable>
                        <template #default="{ row }">
                            <el-link type="primary">
                                ￥-{{ row.pay_money }}
                            </el-link>
                        </template>
                    </vxe-column>

                    <!-- 冲销金额 -->
                    <vxe-column width="120" field="pay_money_offset" title="冲销金额" sortable>
                        <template #default="{ row }">
                            <el-link type="primary">
                                ￥-{{ row.pay_money_offset }}
                            </el-link>
                        </template>
                    </vxe-column>

                    <!-- 记业绩金额 -->
                    <vxe-column width="120" field="pay_money_performance" title="记业绩金额" sortable>
                        <template #default="{ row }">
                            <el-link type="success">
                                ￥-{{ row.pay_money_performance }}
                            </el-link>
                        </template>
                    </vxe-column>

                    <!-- 对应客户 -->
                    <vxe-column width="200" field="customer.name" title="对应客户">
                        <template #default="{ row }">
                            <el-link type="primary">
                                {{ row.customer.name }}
                            </el-link>
                        </template>
                    </vxe-column>

                    <!-- 对应合同 -->
                    <vxe-column min-width="250" field="contract.name" title="对应合同">
                        <template #default="{ row }">
                            <el-link type="primary" @click="$refs.drawerContractDetails.Open(row.contract.id)">
                                {{ row.contract.number }} - {{ row.contract.name }}
                            </el-link>
                        </template>
                    </vxe-column>

                    <!-- 退款负责人 -->
                    <vxe-column width="80" field="user.name" title="负责人"></vxe-column>

                    <!-- 开具发票 -->
                    <vxe-column width="100" field="invoice_state" title="开具发票">
                        <template #default="{ row }">
                            <el-link v-if="row.invoice_state == 0" type="warning">未开具发票</el-link>
                            <el-link v-if="row.invoice_state == 1" type="success">已开具发票</el-link>
                            <el-link v-if="row.invoice_state == 2" type="info">无需开具发票</el-link>
                        </template>
                    </vxe-column>

                    <!-- 退款日期 -->
                    <vxe-column width="100" field="pay_date" title="退款日期" sortable></vxe-column>

                    <!-- 退款备注 -->
                    <vxe-column width="200" field="describe" title="退款备注"></vxe-column>

                    <!-- 创建者 -->
                    <vxe-column width="80" field="created_user.name" title="创建者"></vxe-column>

                    <!-- 更新者 -->
                    <vxe-column width="80" field="updated_user.name" title="更新者"></vxe-column>

                    <vxe-column width="140" title="操作" fixed="right" align="center">
                        <template #default="{ row }">
                            <el-link @click="$refs.drawerUpdate.Open(row.id)" icon="el-icon-edit-outline" type="primary" :underline="false">编辑</el-link>
                            <el-link @click="TableDelete(row.id)" icon="el-icon-delete" type="danger" :underline="false" class="w-margin-l-10">删除</el-link>
                        </template>
                    </vxe-column>
                </vxe-table>
            </template>

            <!-- 分页 -->
            <template slot="pager">
                <vxe-pager :total="page.total" :page-size.sync="page.size" :current-page.sync="page.current" @page-change="TableSearch()"></vxe-pager>
            </template>
        </uw-table>

        <!-- 组件 -->
        <contract-pay-out-create ref="drawerCreate" @success="TableSearch" />
        <contract-pay-out-update ref="drawerUpdate" @success="TableSearch" />
        <crm-contract-drawer ref="drawerContractDetails" @onChange="TableSearch" />
    </uw-content>
</template>

<script>
export default {
    data () {
        return {

            loading: false,
            
            table: [],
            statistics: {},

            // 分页
            page: {
                total: 0,
                size: 50,
                current: 1,
            },

            // 检索
            search: {
                contract_id: null,
                pay_type: 0,
                invoice_state: null,
                pay_date: null
            },

            // 排序
            order: {
                method: 'desc',
                column: 'pay_date'
            },
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {

        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.loading = true
            this.$http.post(
                '/9api/my/contract-pay-record',
                {
                    page: this.page,
                    search: this.search,
                    order: this.order,
                    statistics: true,
                    append: ['user', 'customer', 'contract', 'created_user', 'updated_user']
                }
            ).then((rps) => {
                // 更新数据
                this.page.total = rps.data.total
                this.table = rps.data.body
                this.statistics = rps.data.statistics

                // 页码溢出
                if (this.table.length == 0 && this.page.current != 1) {
                    this.page.current = 1
                    this.TableSearch()
                }

                // 切换加载状态
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 数据排序
        |--------------------------------------------------
        |
        */

        TableSort(column) {
            this.order.method = column.order ? column.order : this.order.method
            this.order.column = column.field
            this.TableSearch()
        },

        /**
        |--------------------------------------------------
        | 表尾数据
        |--------------------------------------------------
        |
        */

        TableFooterMethod() {
            return [
                ['本页', '￥-' + this.statistics.page?.pay_money.toLocaleString(), '￥-' + this.statistics.page?.pay_money_offset.toLocaleString(), '￥-' + this.statistics.page?.pay_money_performance.toLocaleString()],
                ['合计', '￥-' + this.statistics.all?.pay_money.toLocaleString(), '￥-' + this.statistics.all?.pay_money_offset.toLocaleString(), '￥-' + this.statistics.page?.pay_money_performance.toLocaleString()]
            ]
        },

        /**
        |--------------------------------------------------
        | 表格数据 - 移除
        |--------------------------------------------------
        |
        */

        TableDelete(id) {
            this.loading = true
            this.$http.post('/9api/crm/contract-pay-record/delete', { id: id }).then((rps) => {
                this.TableSearch()
            })
        },
    }
}
</script>